import React, {useState} from 'react'
import View from "../theme/View";
import TextField from "../theme/TextField";
import Spacer from "../Spacer/Spacer";


const JudgeFeedback = ({initialFeedback, onFeedbackChange}) => {
   const [liked, setLiked] = useState(initialFeedback?.[0] || '');
   const [improving, setImproving] = useState(initialFeedback?.[1] || '');
   const [change, setChange] = useState(initialFeedback?.[2] || '');
   const isXS = window.innerWidth < 600;


   return (
      <View row={!isXS}
            column={isXS}
         style={{ display: 'inline-flex', justifyContent: 'space-around', width: '100%'}}>
         <TextField
            value={liked}
            onChange={(event) => {
               setLiked(event.target.value);
               onFeedbackChange(event.target.value, 0);
            }}
            multiline
            style={{ display: 'inline-flex'}}
            label="What you liked"
            rows={4}/>
         <Spacer horizontal/>
         <TextField
            value={improving}
            multiline
            onChange={(event) => {
               setImproving(event.target.value);
               onFeedbackChange(event.target.value, 1);
            }}
            style={{ display: 'inline-flex'}}
            label="What could be improved"
            rows={4}/>
         <Spacer horizontal/>
         <TextField
            value={change}
            multiline
            onChange={(event) => {
               setChange(event.target.value);
               onFeedbackChange(event.target.value, 2);
            }}
            style={{ display: 'inline-flex'}}
            label="What should change/expand"
            rows={4}/>
      </View>
   )
}

export default JudgeFeedback
