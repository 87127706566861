import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    paddingVertical: props => ({
        '& input': {
            paddingTop: theme.spacing(props.paddingVertical),
            paddingBottom: theme.spacing(props.paddingVertical),
        },
    }),
}));

const TextFieldCustom = ({
                       className,
                       color = 'primary',
                       fullWidth = true,
                       margin = 'normal',
                       paddingVertical = 0,
                       variant = 'outlined',
                       ...rest
                   }) => {

    const classes = useStyles({paddingVertical});

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.paddingVertical]: paddingVertical > 0,
        },
        className
    );

    return (
        <TextField
            color={color}
            fullWidth={fullWidth}
            variant={variant}
            margin={margin}
            {...rest}
            className={rootClassName}
        />
    );
};

TextField.propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    classes: PropTypes.object,
    color: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.node,
    id: PropTypes.string,
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    label: PropTypes.node,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
    rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
    select: PropTypes.bool,
    SelectProps: PropTypes.object,
    size: PropTypes.oneOf(['medium', 'small']),
    type: PropTypes.string,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default TextFieldCustom;
