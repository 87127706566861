import React from "react";

import {AppBar, IconButton, Slide, Toolbar, useScrollTrigger, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import S from "../../../res/styles";
import View from "../../../components/theme/View";
import MenuElements from "../Menu/MenuElements";
import {useViewport} from "../../../utils/dimensions/Viewport";
import {Menu} from "@mui/icons-material";

function ElevationScroll(props) {
   const {children, window, theme} = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 150,
      target: window ? window() : undefined,
   });

   return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      color: trigger ? theme.palette.background.default : 'transparent',
   });
}

ElevationScroll.propTypes = {
   children: PropTypes.element.isRequired,
   /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
   window: PropTypes.func,
};

function HideOnScroll(props) {
   const {children, window} = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({target: window ? window() : undefined});
   return (
      <Slide appear={false} direction="down" in={!trigger}>
         {children}
      </Slide>
   );
}

const Topbar = ({mount, handleDrawerToggle, oppositeColor, ...rest}) => {
   const classes = useStyles();
   const theme = useTheme();
   const {isXS} = useViewport();

   return (
      <HideOnScroll theme={theme} {...rest}>
         <AppBar position="absolute" color={'transparent'} className={classes.appBar} elevation={0}>
            <nav>
               <Toolbar className={classes.toolbar}>
                  <View className={classes.toolbarBody} alignItems={'center'}>
                     <View hiddenUp={'sm'}>
                        <IconButton
                           color="inherit"
                           aria-label="open drawer"
                           edge="start"
                           onClick={handleDrawerToggle}
                           className={classes.menuButton}
                        >
                           <Menu style={{fontSize: 32}}/>
                        </IconButton>
                     </View>
                     <View/>
                     {/*<Logo size={isXS ? 30 : 40} oppositeColor={oppositeColor}/>*/}
                     <View hiddenDown={'xs'}>
                        {mount &&
                        <MenuElements oppositeColor={oppositeColor}/>
                        }
                     </View>
                  </View>
               </Toolbar>
            </nav>
         </AppBar>
      </HideOnScroll>
   )
};

Topbar.propTypes = {
   handleDrawerToggle: PropTypes.func,
};


const useStyles = makeStyles((theme) => ({
   root: {},
   appBar: {
      //backgroundColor: theme.palette.background.default,
      //backgroundColor: 'red',
      /*[theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${R.dimensions.drawerWidth}px)`,
          marginLeft: R.dimensions.drawerWidth,
      },*/
      transition: theme.transitions.create(['background'],
         {duration: theme.transitions.duration.shorter,}),
   },
   menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
         display: 'none',
      },
      color: '#fff',
   },
   toolbar: {
      ...S.layout.justifyBetween,
      ...S.layout.centerCenter
   },
   toolbarBody: {
      ...S.layout.justifyBetween,
      maxWidth: 1200,
      width: '100%',
   },
}));


export default Topbar;
