import React, {useState} from 'react'
import {makeStyles} from "@mui/styles";
import Typography from "../theme/Typography";
import R from "../../res/variables";
import {Accordion, AccordionDetails, AccordionSummary, capitalize, Dialog, lighten} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CompanyOverview from "../Company/CompanyOverview";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionsList from "./QuestionsList";
import View from "../theme/View";
import Spacer from "../Spacer/Spacer";
import {entriesMap} from "../../utils/entriesMap";
import moment from "moment";
import JudgeFeedback from "../JudgeFeedback/JudgeFeedback";
import {useViewport} from "../../utils/dimensions/Viewport";

const VoteBox = ({
                     item,
                     index,
                     questsList,
                     questsDescription,
                     onVoteChange,
                     onFeedbackChange,
                     initialVotes,
                     initialFeedback,
                     round,
                     submitError,
                     isPublic,
                     submitted
                 }) => {

    const classes = useStyles();
    const [imageOpen, setImageOpen] = useState(false);
    const [publicDialogOpen, setPublicDialogOpen] = useState(false);

    const isXS = window.innerWidth < 600;

    /*const imagesList = [
        'https://cdn.thewirecutter.com/wp-content/media/2021/07/drones-2048px-2x1-9431.jpg?auto=webp&quality=75&crop=2:1&width=1024',
        'https://i.guim.co.uk/img/media/a921d55139061ed100eb4949c3560b5e284851ac/0_2067_4750_2849/master/4750.jpg?width=465&quality=45&auto=format&fit=max&dpr=2&s=90d8c2dffec16233d56b59039368e985',
        'https://media.wsimag.com/attachments/2731fa291b873492ad8e65b45daf4dd2e349ea1f/store/fill/1230/692/d001e4213ab6ce27bf494b503a91d4ed7fb324c4f7fd1e47f4c540a97f9e/It-is-almost-impossible-to-know-what-kind-of-data-will-be-gathered-by-drones-all-over-the-world.jpg',
        'https://img.etimg.com/thumb/msid-85907011,width-1200,height-900,imgsize-20008,resizemode-8,quality-100/opinion/et-commentary/sky-is-the-limit-how-drones-have-dramatically-changed-the-world.jpg',
        'https://o7dkx1gd2bwwexip1qwjpplu-wpengine.netdna-ssl.com/wp-content/uploads/2019/06/Drone-1000x380.jpg',
        'https://img.etimg.com/thumb/msid-85907011,width-1200,height-900,imgsize-20008,resizemode-8,quality-100/opinion/et-commentary/sky-is-the-limit-how-drones-have-dramatically-changed-the-world.jpg',
    ]*/
    const handleShowDialog = (index) => {
        imageOpen ? setImageOpen(false) : setImageOpen(index)
    };
    const handlePublicDetailsDialog = (index) => {
        publicDialogOpen ? setPublicDialogOpen(false) : setPublicDialogOpen(index)
    };

    const companyDetails = () => {
        return (
           <View style={{padding: isPublic ? 30 : 0}}>
               {isPublic && getCompanyHeader(true)}
               <View
                  style={{width: '100%', display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["use_case_country"]}</Typography>
                       <Typography style={{display: 'inline-flex',}}
                                   variant={'body1'}>{item.entry_info["use_case_country"]}</Typography>
                   </View>
                   <Spacer horizontal/>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["is_under_embargo"]}</Typography>
                       <Typography variant={'body1'}
                                   style={{display: 'inline-flex',}}>{item.entry_info["is_under_embargo"] ? "Yes" : "No"}</Typography>
                   </View>
                   <Spacer horizontal/>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["launch_date"]}</Typography>
                       <Typography style={{display: 'inline-flex',}}
                                   variant={'body1'}>{moment(item.entry_info["launch_date"]).format('DD/MM/YYYY')}</Typography>
                   </View>
                   {round === 'round_3' && <Spacer horizontal/>}
                   {round === 'round_3' &&
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["video_url"]}</Typography>
                       <a href={item.entry_info["video_url"]} target="_blank">Link to video</a>
                   </View>
                   }
               </View>
               <Spacer/>
               {imageOpen && (
                  <Dialog
                     disableRestoreFocus
                     disableScrollLock
                     open={imageOpen}
                     onClose={() => handleShowDialog(false)}
                  >
                      <img
                         className="image"
                         src={item.images[imageOpen - 1]}
                         alt={"image_expanded_" + imageOpen}
                      />
                  </Dialog>
               )}
               <View flex row style={{overflowX: 'auto'}}>
                   {item.images && item.images.map((item, index) => (
                      <img
                         className={classes.imageCarosel}
                         src={item}
                         loading="lazy"
                         onClick={() => handleShowDialog(index + 1)}
                         alt={"image_" + index + 1}
                      />
                   ))}
               </View>
               <CompanyOverview item={item} round={round}/>
           </View>
        )
    }
    const companyPublicVotingDetails = () => {
        let socials = item.company_info["social_media_urls"].split("\n");
        return (
           <View style={{padding: isPublic ? 30 : 0, width: isXS ? window.innerWidth * 0.8 : 500}}>
               {getCompanyHeader(true)}
               <View style={{
                   width: '100%',
                   display: 'inline-flex',
                   flexDirection: 'column',
                   justifyContent: 'space-around'
               }}>
                   {item.company_info['group'] === 'Person' &&
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["company_name"]}</Typography>
                       <Typography variant={'body1'}
                                   style={{display: 'inline-flex',}}>{item.company_info["company_name"]}</Typography>
                       <Spacer/>
                   </View>
                   }
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["website_url"]}</Typography>
                       <a href={item.company_info["website_url"]} target="_blank">{"Link to website"}</a>
                       <Spacer/>
                   </View>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["social_media_urls"]}</Typography>
                       {socials.map((item, index) => (
                          <View>
                             {index !== 0 && <Spacer size={0.5}/>}
                              <a href={item} target="_blank">{capitalize(item.replace(/.+\/\/|www.|\..+/g, ''))}</a>
                          </View>
                       ))}
                      <Spacer/>
                   </View>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{display: 'inline-flex', fontWeight: 700}}>{entriesMap["reason"]}</Typography>
                       <Typography style={{display: 'inline-flex',}}
                                   variant={'body1'}>{item.company_info["reason"]}</Typography>
                       <Spacer/>
                   </View>
                   <View style={{display: 'inline-flex', flexDirection: 'column'}}>
                       <Typography variant={'body1'}
                                   style={{
                                       display: 'inline-flex',
                                       fontWeight: 700
                                   }}>{entriesMap["statement"]}</Typography>
                       <Typography style={{display: 'inline-flex',}}
                                   variant={'body1'}>{item.company_info["statement"]}</Typography>
                       <Spacer/>
                   </View>
               </View>

           </View>
        )
    }

    const getCompanyHeader = (inDetails = false) => {

        return (
           <View flex column={isPublic || isXS} row={!isPublic && !isXS} alignItems={'center'} clickable={!inDetails}>
               {!isPublic && !inDetails ?
                  <View flex row={true} alignItems={'center'} clickable>
                      {submitted &&
                      <View style={{width: 30, height: 30, position: 'absolute', right: isXS ? 0 : 20, top: 10}}>
                          <CheckCircleIcon style={{color: 'green'}}/>
                      </View>}
                      <View style={{height: 170, width: isXS ? '100%' : inDetails ? '100%' : 250}}>
                          <img src={item.entry_info.cover_image}
                               style={{
                                   height: 170,
                                   width: isXS ? '100%' : 250,
                                   objectFit: 'cover',
                                   borderRadius: 15
                               }}/>
                      </View>
                      <Spacer horizontal={!isXS}/>
                      <View column flex>
                          <Typography capitalize variant={'h6'}
                                      style={{fontWeight: 700}}>{item.company_info.company_name}</Typography>
                          <Spacer/>
                          <View row>
                              <Typography variant={'body1'}
                                          style={{fontWeight: 700}}>{entriesMap["entry_title"]}</Typography>
                              <Typography variant={'body1'}>{item.entry_info["entry_title"]}</Typography>
                          </View>
                          <Spacer/>
                          <View row>
                              <Typography variant={'body1'}
                                          style={{fontWeight: 700}}>{entriesMap["entry_description"]}</Typography>
                              <Typography variant={'body1'}>{item.entry_info["entry_description"]}</Typography>
                          </View>
                          {(inDetails) &&
                          <Spacer/>
                          }
                      </View>
                  </View>
                  :
                  inDetails ?
                     <View style={{width: isXS ? '100%' : '100%'}}>
                         <View style={{height: 170, width: '100%'}}>
                             <img src={item.company_info.cover_image}
                                  style={{height: 170, width: '100%', objectFit: 'cover', borderRadius: 15}}/>
                         </View>
                         <Spacer/>
                         <View column flex>
                             <Typography capitalize variant={'h6'}
                                         style={{fontWeight: 700}}>{item.company_info.group === 'Person' ? item.company_info.first_name + ' ' + item.company_info.last_name : item.company_info.company_name}</Typography>
                             <Spacer/>
                         </View>
                     </View>
                     :
                     <View style={{width: isXS ? '100%' : '100%', backgroundColor: R.palette.greySet[900]}}>
                         <View style={{height: 170, width: isXS ? '100%' : '100%'}}>
                             <img src={item.company_info.cover_image}
                                  style={{height: 170, width: isXS ? '100%' : '100%', objectFit: 'cover'}}/>
                         </View>
                         <Spacer/>
                         <View flex style={{justifyContent: 'center'}}>
                             <Typography maxLines={1} capitalize variant={'h6'}
                                         style={{
                                             fontWeight: 700,
                                             textAlign: 'center',
                                             color: 'white'
                                         }}>{item.company_info.group === 'Person' ? item.company_info.first_name + ' ' + item.company_info.last_name : item.company_info.company_name}</Typography>
                         </View>
                         <Spacer/>
                     </View>
               }
           </View>
        )
    }

    return (
       isPublic ?
          <View className={classes.publicElement} onClick={() => handlePublicDetailsDialog(index + 1)}>
              {getCompanyHeader()}
              {publicDialogOpen && (
                 <Dialog
                    disableRestoreFocus
                    disableScrollLock
                    open={publicDialogOpen}
                    onClose={() => handlePublicDetailsDialog(false)}
                 >
                     {companyPublicVotingDetails()}
                 </Dialog>
              )}
          </View>
          :
          <Accordion className={classes.accordion} style={{borderRadius: 15}}>
              <AccordionSummary
                 expandIcon={<ExpandMoreIcon/>}
                 aria-controls={"panel1a-content" + index}
                 id={"panel1a-header" + index}
                 className={classes.accordionSummary}
                 style={{paddingLeft: 15,}}
              >
                  {getCompanyHeader()}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                  {companyDetails()}
                  {
                      round !== 'round_3' &&
                      <QuestionsList id={item.company_info.id} questsList={questsList}
                                     questsDescription={questsDescription}
                                     onVoteChange={onVoteChange} initialVotes={initialVotes}
                                     round={round}
                                     error={submitError?.[item.company_info.id]}
                      />
                  }
                  {
                      round === 'round_2' &&
                      <View>
                          <Spacer/>
                          <Typography capitalize variant={'h6'}
                                      style={{fontWeight: 700}}>{"Your Feedback"}</Typography>
                          <JudgeFeedback
                             initialFeedback={initialFeedback}
                             onFeedbackChange={onFeedbackChange}
                          />
                      </View>

                  }
              </AccordionDetails>
          </Accordion>

    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    wrapper: {
        width: '100%',
        maxWidth: 700
    },
    heading: {},
    publicElement: {
        cursor: 'pointer',
        /*padding: 20,
        backgroundColor: lighten(R.palette.grey, 0.2),
        borderRadius:15,*/
        /*border:'solid 1px #eee'*/
    },
    accordion: {
        cursor: 'pointer',
        paddingTop: 10,
        paddingBottom: 0,
        color: theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.1)),
        '& .MuiIconButton-root': {
            color: theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.1)),
        },
        '&.MuiAccordion-rounded': {
            backgroundColor: lighten(R.palette.grey, 0.2),
        },
        '&.MuiAccordion-root:before': {
            backgroundColor: 'transparent',
        },
        margin: '10px 0',
        '&.MuiAccordion-root.Mui-expanded': {
            margin: '10px 0',
            marginBottom: 120
        },
        overflow: 'hidden'
        //borderRadius: 20,
    },
    accordionSummary: {
        cursor: 'pointer',
        paddingLeft: 0,
        paddingTop: 10,
        paddingBottom: 10,
        '& .MuiAccordionSummary-content': {
            margin: '0px 0',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px 0',
        },
    },
    accordionDetails: {},
    imageCarosel: {
        height: 150,
        width: 200,
        objectFit: 'cover',
    },
    cutDescription: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineHeight: 16, /* fallback */
        maxHeight: 32, /* fallback */
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    }
}));

export default VoteBox
