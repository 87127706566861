import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {darkenColor, IsColorInPalette} from "../../utils/colors/Colors";
import View from "./View";
import R from "../../res/variables";
import CircularProgress from "./CircularProgress";
import {Button as MuiButton} from "@mui/material";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles(theme => ({
    root: {},
    color: props => ({
        backgroundColor: props.colorInPalette ? theme.palette[props.color]['main'] : props.color,
        color: props.colorInPalette ? theme.palette.getContrastText(theme.palette[props.color]['main']) : theme.palette.getContrastText(props.color),
        '&:hover': {
            backgroundColor: props.colorInPalette ? theme.palette[props.color]['dark'] : darkenColor(props.color, 0.2),
        },
    }),
    noHover:{
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    fontSize: props => ({
        fontSize: props.fontSize
    }),
    buttonXl: {
        fontSize: '1.25rem',
        padding: '12px 30px'
    },
    fullWidth: {
        flex: 1,
        width: '100%'
    },
    oppositeColor: {
        color: R.palette.themeDark ? R.palette.textPrimaryLight : R.palette.textPrimaryDark,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: -22,
        marginTop: -6,
    },
    wrapper: {
        position: 'relative',
    },
}));

const Button = ({
                    props,
                    className,
                    children,
                    fullWidth,
                    color = 'primary',
                    variant = 'contained',
                    size = 'medium',
                    noHover,
                    oppositeColor,
                    loading = false,
                    ...rest
                }) => {

    const colorInPalette = IsColorInPalette(color);
    const classes = useStyles({color, colorInPalette, size});

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.color]: !!color && variant === 'contained',
            [classes.buttonXl]: size && size === 'xLarge',
            [classes.fontSize]: size && (size !== 'small' && size !== 'medium' && size !== 'large' && size !== 'xLarge'),
            [classes.noHover]: noHover,
            [classes.oppositeColor]: oppositeColor,
            [classes.fullWidth]: fullWidth,
        },
        className
    );

    return (
        <View column={fullWidth}>
            <MuiButton
                variant={variant}
                disabled={loading}
                size={size}
                fullWidth={fullWidth}
                {...rest}
                className={rootClassName}
            >
                <View className={classes.wrapper}>
                {loading && <CircularProgress size={12} className={classes.buttonProgress} thickness={4} color={'inherit'}/>}
                {children}
                </View>
            </MuiButton>
        </View>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    fullWidth: PropTypes.bool,
    noHover: PropTypes.bool,
    oppositeColor: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf(['small', 'medium', 'large', 'xLarge'])])
};

export default Button;
