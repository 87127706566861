import React from 'react'

import View from "../../../components/theme/View";
import Spacer from "../../../components/Spacer/Spacer";
import {ListItemIcon} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Android, Apple, Facebook, Instagram, WhatsApp} from "@mui/icons-material";
const SocialMediaMenuIcons = ({icon, icon2}) => {
    const classes = useStyles();

    return (
        <View centerCenter>
            {icon === 'facebook' && icon2 === 'ios' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Facebook className={classes.icon} style={{width:18,height:18,color: '#2196F3'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Apple className={classes.icon} style={{width:18,height:18,color: '#B3B3B3'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
            {icon === 'facebook' && icon2 === 'android' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Facebook className={classes.icon} style={{width:18,height:18,color: '#2196F3'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Android className={classes.icon} style={{width:18,height:18,color: '#A4C639'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
            {icon === 'instagram' && icon2 === 'ios' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Instagram className={classes.icon} style={{width:18,height:18,color: '#C837AB'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Apple className={classes.icon} style={{width:18,height:18,color: '#B3B3B3'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
            {icon === 'instagram' && icon2 === 'android' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Instagram className={classes.icon} style={{width:18,height:18,color: '#C837AB'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Android className={classes.icon} style={{width:18,height:18,color: '#A4C639'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
            {icon === 'whatsapp' && icon2 === 'ios' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <WhatsApp className={classes.icon} style={{width:18,height:18,color: '#28D146'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Apple className={classes.icon} style={{width:18,height:18,color: '#B3B3B3'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
            {icon === 'whatsapp' && icon2 === 'android' &&
            <ListItemIcon>
                <View className={[classes.iconWrapper]} centerCenter>
                    <WhatsApp className={classes.icon} style={{width:18,height:18,color: '#28D146'}}/>
                </View>
                <Spacer size={0.2} horizontal/>
                <View className={[classes.iconWrapper]} centerCenter>
                    <Android className={classes.icon} style={{width:18,height:18,color: '#A4C639'}}/>
                </View>
                <Spacer size={1} horizontal/>
            </ListItemIcon>
            }
        </View>
    )
}

const useStyles = makeStyles((theme) => ({
    iconWrapper: {
        borderRadius: 4,
        padding: 1,
    },
    whatsappColor:{
        backgroundColor: '#28D146',
    },
    instagramColor:{
        backgroundColor: '#C837AB',
    },
    facebookColor:{
        backgroundColor: '#2196F3',
    },
    androidColor:{
        color: '#A4C639',
    },
    appleColor:{
        backgroundColor: '#B3B3B3',
    },
    icon:{
        width:18,
        height:18,
        fontSize:2,
        color:'#fff'
    }
}));

export default SocialMediaMenuIcons
