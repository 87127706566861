import React from 'react'
import View from "../theme/View";
import Typography from "../theme/Typography";
import round_2_gif from '../../assets/thanks_round_2.gif'
import round_1_gif from '../../assets/thanks_round_1.gif'
import round_3_gif from '../../assets/thanks_round_3.gif'
import Spacer from "../Spacer/Spacer";

const ThanksPage = ({round}) => {

   return (
      <View>
         {round !== 'round_3' &&
         <View style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <img style={{width: '40%', borderRadius: 5}}
                 src={round === 'round_1' ? round_1_gif : round === 'round_2' ? round_2_gif : round_3_gif}
                 alt="Thank you!"/>
         </View>
         }
         <Spacer big/>
         <Typography
            align={'center'}
            variant={'h5'}
            style={{fontWeight: 700}}
         >
            {round === 'round_1' ? "Thanks for competing Round 1 of judging - you're a star!" : round === 'round_2' ? "Thanks for completing Round 2 of judging - how epic is that!" : "Wow! You've made it and completed all round of judging for Airwards 21/22"}
         </Typography>
         <Spacer/>
         <Typography
            align={'center'}
            variant={'h6'}
            style={{fontWeight: 400}}
         >
            {round === 'round_1' ? "You're 1/3 of the way through the judging for Airwards 21/22 - keep going!" : round === 'round_2' ? "You're more than 2/3 of the way through the judging for Airwards 21/22 - nearly there!" : "You have made a huge difference to a company promoting positive drone use and demonstrating that \'Life's Better With Drones\'"}
         </Typography>
         <Spacer/>
         {round !== 'round_3' && <Typography align={'center'} variant={'body1'}>
            Head <a href={"https://www.airwards.online/judges/login/overview"}>here</a> to our judges login area for
            more information
         </Typography>}
         <Typography
            align={'center'}
            variant={'body1'}
         >
            {round === 'round_1' ? "...As we request more information for our entrants who have made it through Round 1," : round === 'round_2' ? "" : "Now go and make yourself a huge well earned tea (or something stronger...) because you deserve it!"}
         </Typography>
         {round === 'round_1' && <Typography
            align={'center'}
            variant={'body1'}
         >
            why not nominate individuals or companies for our <a
            href={"https://www.airwards.online/peoples-choice/nomination"}>People's Choice categories</a>
         </Typography>}
         {round === 'round_2' && <Typography
            align={'center'}
            variant={'body1'}
         >
            ... In the meantime why not <a href={"https://www.airwards.online/peoples-choice/vote"}>vote</a> for some of
            the People's Choice nominees as we head into the final Round 3 of judging soon
         </Typography>}
         {round === 'round_3' &&
         <View><Spacer>
            <View style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
               <img style={{width: '30%', borderRadius: 5}}
                    src={round === 'round_1' ? round_1_gif : round === 'round_2' ? round_2_gif : round_3_gif}
                    alt="Thank you!"/>
            </View>
         </Spacer>
         </View>
         }
         {round === 'round_3' && <Typography
            align={'center'}
            variant={'body1'}
         >
            ... Well not quite ... we still have our Airwards Winners' Week to go, where we'll announce the winners - so
            be sure to tune in then
         </Typography>}
      </View>
   )
}

export default ThanksPage
