import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import {Divider} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    vertical: {
        paddingTop: size => theme.spacing(1*size) ,
        paddingBottom: size => theme.spacing(1*size),
    },
    horizontal: {
        paddingLeft: size => theme.spacing(1* size) ,
        paddingRight: size => theme.spacing(1* size),
    },
    hide: {
        display: 'none'
    }
}));

const Spacer = ({children, className, divider, horizontal, size, hide}) => {
    const classes = useStyles(size);
    const rootClassName = classNames(
        {
            [classes.vertical]: !horizontal,
            [classes.horizontal]: horizontal,
            [classes.hide]: hide,
        },
        className
    );

    return (
        <div className={rootClassName}>
            {divider ?
                <Divider/>
                :
                null
            }
            {children}
        </div>
    )
};

Spacer.propTypes = {
    children: PropTypes.node,
    size: PropTypes.number,
    horizontal: PropTypes.bool,
    divider: PropTypes.bool,
};
Spacer.defaultProps = {
    size: 1,
    horizontal: false,
    divider: false,
};

export default Spacer
