import React from "react";
import SignIn from "./pages/signin/SigninPage";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AuthProvider, useAuth} from "./contexts/authContext";
import Import from "./pages/import/ImportPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";
import PublicVoting from "./pages/publicVoting/publicVotingPage";
import {CssBaseline} from "@mui/material";

const theme = createTheme({
   typography: {
      fontFamily: 'Rubik',
   },
   components: {
      MuiTypography: {
         defaultProps: {
            fontFamily: "Rubik"
         }
      },
   },
});

export default function App() {
   return (
      <div>
         <ThemeProvider theme={theme}>
            <AuthProvider>
               <Routes>
                  <Route
                     path="/"
                     element={
                        <RequireAuth>
                           <DashboardPage/>
                        </RequireAuth>
                     }
                  />
                  <Route path="/login" element={<SignIn/>}/>
                  <Route path="/logout" element={<Logout/>}/>
                  <Route path="/maketheimport" element={<Import/>}/>
                  <Route path="/voting" element={<PublicVoting/>}/>
               </Routes>
            </AuthProvider>
         </ThemeProvider>
      </div>
   );

   function RequireAuth({children}) {
      let auth = useAuth();
      let location = useLocation();

      // TODO re-add
      if (!auth.user) {
         return <Navigate to="/login" state={{from: location}}/>;
      }
      // return <Navigate to="/" state={{from: location}}/>;

      return children;
   }

   function Logout() {
      let auth = useAuth();
      let location = useLocation();
      auth.signout();
      return <Navigate to="/login" state={{from: location}}/>;
   }

}

