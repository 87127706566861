import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Typography as MuiTypography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {IsColorInPalette} from "../../utils/colors/Colors";
import R from "../../res/variables";


const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'default'
    },
    maxLines: props => ({
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: props.maxLines ? props.maxLines : 1,
        display: "-webkit-box",
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }),
    color: props => ({
        color: props.colorInPalette ? theme.palette[props.color]['main'] : props.finalColor,
    }),
    oppositeColor: {
        color: R.palette.themeDark ? R.palette.textPrimaryLight : R.palette.textPrimaryDark,
    },
    weight: props => ({
        fontWeight: props.weight && props.weight,
    }),
    clickable: {
        cursor: 'pointer'
    },
    italic:{
        fontStyle: 'italic'
    },
    uppercase:{
        textTransform: 'uppercase'
    },
    lowercase:{
        textTransform: 'lowercase'
    },
    capitalize:{
        textTransform: 'capitalize'
    },
}));

const Typography = ({
                        className,
                        children,
                        align,
                        color,
                        variant,
                        component,
                        paragraph,
                        noWrap,
                        maxLines,
                        weight,
                        clickable,
                        oppositeColor,
                        italic,
                        uppercase,
                        lowercase,
                        capitalize,
                        ...rest
                    }) => {

    const isDefaultColor = color && (color === 'textPrimary' || color === 'textSecondary' || color === 'initial' || color === 'inherit' || color === 'primary' || color === 'secondary' || color === 'error');
    const finalColor = oppositeColor ? R.palette.themeDark ? R.palette.textPrimaryLight : R.palette.textPrimaryDark : color;
    let colorInPalette;
    if (!isDefaultColor) {
        colorInPalette = IsColorInPalette(color);
    }
    const classes = useStyles({maxLines, colorInPalette, finalColor, weight, oppositeColor});

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.maxLines]: maxLines,
            [classes.color]: finalColor && !isDefaultColor,
            [classes.oppositeColor]: oppositeColor,
            [classes.weight]: weight,
            [classes.clickable]: clickable,
            [classes.italic]: italic,
            [classes.uppercase]: uppercase,
            [classes.lowercase]: lowercase,
            [classes.capitalize]: capitalize,
        },
        className
    );

    return (
        <MuiTypography
            align={align}
            color={isDefaultColor ? finalColor : 'initial'}
            variant={variant}
            component={component}
            paragraph={paragraph}
            noWrap={noWrap}
            {...rest}
            className={rootClassName}
        >
            {children}
        </MuiTypography>
    );
};

Typography.propTypes = {
    children: PropTypes.node,
    align: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
        'srOnly',
        'inherit',
    ]),
    component: PropTypes.elementType,
    clickable: PropTypes.bool,
    paragraph: PropTypes.bool,
    noWrap: PropTypes.bool,
    maxLines: PropTypes.number,
    weight: PropTypes.oneOf([
        'normal',
        'bold',
        'inherit',
        'initial',
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
    ]),
    capitalize: PropTypes.bool,
    lowercase: PropTypes.bool,
    uppercase: PropTypes.bool,
};
Typography.defaultProps = {
    align: 'inherit',
    color: 'initial',
    variant: 'body1',
};

export default Typography;
