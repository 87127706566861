import React from 'react'
import View from "../../../components/theme/View";
import MenuHover from "./MenuHover";
import menuStructure from "../../../menuStructure";

const MenuElements = ({oppositeColor}) => {
    return (
        <View row flex>
            {
                menuStructure.map(function (element) {
                    return (
                        <MenuHover
                            oppositeColor={oppositeColor}
                            key={element.title}
                            title={element.title}
                            href={element.href}
                            elements={element.subMenu}
                        />
                    );
                })
            }
        </View>
    )
}

export default MenuElements
