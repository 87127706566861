import {checkSignin} from "../controllers/signinController";
import {createContext, useContext, useState} from "react";

let AuthContext = createContext(null);

export function AuthProvider({children}) {
   let [user, setUser] = useState(localStorage.getItem("userLogin"));

   let signin = async (email, password) => {
      let user = await checkSignin(email, password)
      localStorage.setItem("userLogin", user)
      setUser(user);
   };

   let signout = () => {
      localStorage.setItem("userLogin", null)
      setUser(null);
   };

   let value = {user, signin, signout};

   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
   return useContext(AuthContext);
}
