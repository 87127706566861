import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Avatar} from "@mui/material";
import {useAuth} from "../../contexts/authContext";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import View from "../../components/theme/View";


const theme = createTheme();

export default function SignIn() {
   let auth = useAuth();
   let navigate = useNavigate();
   let location = useLocation();
   useEffect(()=> {
      if(auth?.user){
         navigate('/', { replace: true });
      }
   }, [])

   const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await auth.signin(data.get('email')?.toLowerCase(), data.get('password'))
      navigate('/', { replace: true });
   };

   return (
         <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
               sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               {/*<Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                  <LockOutlinedIcon/>
               </Avatar>*/}
                <img alt={'logo'} src={'/assets/airwards_logo.png'} style={{width:300, height:100, objectFit:'contain'}}/>
                <Typography component="h1" variant="h5">
                  Sign in
               </Typography>
               <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email Address"
                     name="email"
                     autoComplete="email"
                     autoFocus
                     autoCapitalize='none'
                  />
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Password"
                     type="password"
                     id="password"
                     autoComplete="current-password"
                  />
                  {/*<FormControlLabel
                     control={<Checkbox value="remember" color="primary" />}
                     label="Remember me"
                  />*/}
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     sx={{mt: 3, mb: 2}}
                  >
                     Sign In
                  </Button>
               </Box>
            </Box>
         </Container>
   );
}
