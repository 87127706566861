import React from 'react'
import {makeStyles} from "@mui/styles";
import Typography from "../theme/Typography";
import View from "../theme/View";
import Spacer from "../Spacer/Spacer";
import {categories} from "../../utils/categories/categoriesList";

const CategoryCard = ({item, index, onCategoryClicked, votes, voted}) => {
    const classes = useStyles();
    const disabled = votes?.[item] ? {
            opacity: 0.5,
            '&:hover': {
                opacity: 0.5,
            }
        }
        : {}
    return (
        <>
            <View
                sx={disabled}
                style={{backgroundImage: `url(${categories[item]?.url})`, backgroundSize: 'cover', backgroundPosition: 'center',}}
                className={classes.box}
                onClick={() => !votes?.[item] && onCategoryClicked(item)}
                clickable
                flex
                flex1
                overflowHidden
            >
                <View
                    flex1
                    fullWidth
                    className={classes.shadowView}
                    alignItems={'center'}
                    justify={'flex-end'}
                    column
                >
                <Typography
                    oppositeColor
                    align={'center'}
                    component={'h4'}
                >
                    {item}
                    {voted && " (" + voted + ")"}
                    {votes?.[item] && " (Vote Submitted)"}
                </Typography>
                <Spacer size={0.5}/>
                </View>
            </View>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    wrapper: {
        width: '100%',
    },
    box: {
        paddingTop: 0,
        paddingBottom: 0,
        margin: '10px 0',
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            opacity: 0.8,
        },
        borderRadius: 15,
        cursor: 'pointer',
        minHeight: 200,
    },
    shadowView:{
        backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)'
}
}));

export default CategoryCard
