import React from 'react'
import {makeStyles} from "@mui/styles";
import Typography from "../theme/Typography";
import View from "../theme/View";
import Spacer from "../Spacer/Spacer";
import _ from "lodash";
import {entriesMap} from "../../utils/entriesMap";
import {Divider} from "@mui/material";
import {round_one_criteria, round_two_criteria, round_two_future} from "../../utils/entriesOrders";


const CompanyOverview = ({item, index, round}) => {
   const classes = useStyles();
   let rounds = _.keys(item.answers)

   return (
      <>
         <Spacer/>
         {
            round !== 'round_1' ?
               _.map(['round_2', 'round_1'], (round_name, index) => {
                  return (
                     <View>
                        <Spacer/>
                        {round_name === 'round_1' && <Typography capitalize variant={'h4'}
                                                                 style={{fontWeight: 700}}>{'Round 1 | Criteria'}</Typography>}
                        {/*{round_name === 'round_2' && <Typography capitalize variant={'h6'} style={{fontWeight: 700}}>{'Round 2 | Sub-Criteria'}</Typography>}*/}
                        {
                           round_name === 'round_1' && _.map(round_one_criteria, (o, index) => {
                              return (
                                 <View row>
                                    <Spacer small/>
                                    {entriesMap[o]}
                                    <Typography capitalize variant={'body1'}
                                                style={{fontStyle: 'italic'}}>{item.answers?.[round_name][o]}</Typography>
                                    <Spacer small/>
                                    <Divider/>
                                 </View>
                              )
                           })
                        }
                        {round_name === 'round_2' && _.map(round_two_criteria, (o, index) => {
                           return (
                              <View row>
                                 {o === 'innovation_description_round_2' &&
                                 <View>
                                    <Spacer/>
                                    <Typography capitalize variant={'h4'}
                                                style={{fontWeight: 700}}>{'Round 2 | Sub-Criteria'}</Typography>
                                 </View>
                                 }
                                 <Spacer small/>
                                 {entriesMap[o]}
                                 <Typography capitalize variant={'body1'}
                                             style={{fontStyle: 'italic'}}>{item.answers?.[round_name][o]}</Typography>
                                 <Spacer small/>
                                 <Divider/>
                              </View>
                           )
                        })}
                        {round_name === 'round_2' && _.map(round_two_future, (o, index) => {
                           return (
                              <View row>
                                 {o === 'scalability' &&
                                 <View>
                                    <Spacer/>
                                    <Typography capitalize variant={'h4'}
                                                style={{fontWeight: 700}}>{'Round 2 | Future Plans'}</Typography>
                                 </View>
                                 }
                                 <Spacer small/>
                                 {entriesMap[o]}
                                 <Typography capitalize variant={'body1'}
                                             style={{fontStyle: 'italic'}}>{item.answers?.[round_name][o]}</Typography>
                                 <Spacer small/>
                                 <Divider/>
                              </View>
                           )
                        })}
                        {/*{_.map(round_name === 'round_1' ? round_one_criteria: round_two_criteria, (o, index) => {
                           return (
                              <View row>
                                 {round_name === 'round_2' && o === 'innovation_description' &&
                                 <View>
                                    <Spacer/>
                                    <Typography capitalize variant={'h6'} style={{fontWeight: 700}}>{'Round 2 | Sub-Criteria'}</Typography>
                                 </View>
                                 }
                                 {round_name === 'round_2' && o === 'scalability' &&
                                 <View>
                                    <Spacer/>
                                    <Typography capitalize variant={'h6'} style={{fontWeight: 700}}>{'Round 2 | Future Plans'}</Typography>
                                 </View>
                                 }
                                 <Spacer small/>
                                 <Typography capitalize variant={'body1'}
                                             style={{fontWeight: 700}} component='div'>{entriesMap[o]}</Typography>
                                 <Typography capitalize variant={'body1'}>{item.answers?.[round_name][o]}</Typography>
                                 <Spacer small/>
                                 <Divider/>
                              </View>
                           )
                        })
                        }*/}
                     </View>
                  )
               })
               :
               <View>
                  {round === 'round_1' && <Typography capitalize variant={'h4'}
                                                      style={{fontWeight: 700}}>{'Criteria'}</Typography>}
                  {round === 'round_1' && _.map(round_one_criteria, (o, index) => {
                     return (
                        <View row>
                           <Spacer/>
                           <Typography capitalize variant={'body1'}
                                       style={{fontWeight: 700}}>{entriesMap[o]}</Typography>
                           <Typography capitalize variant={'body1'}>{item.answers?.[round][o]}</Typography>
                           <Spacer small/>
                           <Divider/>
                        </View>
                     )

                  })
                  }
               </View>

         }
         <Spacer/>
      </>
   )
}

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',

   },
   wrapper: {
      width: '100%',
      maxWidth: 700
   },
   heading: {},
}));

export default CompanyOverview
