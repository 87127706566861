import React from 'react'

import {makeStyles} from "@mui/styles";
import {useViewport} from "../../utils/dimensions/Viewport";
import View from "../theme/View";
import Typography from "../theme/Typography";
import Spacer from "../Spacer/Spacer";
import CategoryCard from "./CategoryCard";
import {Grid} from "@mui/material";


const CategoriesGrid = ({categoriesList, onCategoryClicked, votes, round, voted}) => {
    const classes = useStyles();
    const {isXS} = useViewport();

    return (
        <View className={classes.root} centerCenter flex>
            <View className={classes.wrapper}>
                <Typography
                    align={'center'}
                    variant={isXS ? 'h3' : 'h3'}
                    component={'h2'}
                >Categories</Typography>
                <Spacer size={4}/>
                <Grid container spacing={2}>
                    {categoriesList && categoriesList.map((item, index) => (
                        <Grid key={index} item alignItems={'center'} justify={'center'} xs={6} md={4} sm={6}>
                            <CategoryCard
                                item={item}
                                index={index}
                                onCategoryClicked={onCategoryClicked}
                                votes={round === 'round_3' && votes}
                                voted={round !== 'round_3' && voted?.[item]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </View>
        </View>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    wrapper: {
        width: '100%',
    },
}));

export default CategoriesGrid
