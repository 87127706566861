export const categories = {
   "Counter Drone Systems (Anti-Drones)": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg1-min.jpg?alt=media&token=4370ecf3-2dec-425f-a2c8-969240440d56",
      group: "Technology"
   },
   "Sensors and Payload": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg2-min.jpg?alt=media&token=9c73074f-1d7e-4d98-86b0-7fe5688f422a",
      group: "Technology"
   },
   "Software - Captured data management and analysis": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg3-min.jpeg?alt=media&token=123b8bfd-f461-4278-93a8-247e96751cd9",
      group: "Technology"
   },
   "Software - Enabling flight and operational management": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg4-min.jpg?alt=media&token=500612af-f271-4c98-95c4-2066ed66ac76",
      group: "Technology"
   },
   "Airspace Management": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg23-min.jpg?alt=media&token=2a7507f1-bac9-46b0-bb8f-f3cde9be4e1e",
      group: "Operations"
   },
   "Delivery": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg5-min.jpg?alt=media&token=22428547-6fb2-4bbb-bf38-263f049a4ec8",
      group: "Operations"
   },
   "Survey and Inspection": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg6-min.jpg?alt=media&token=c34d99d6-d0f4-4fbd-938f-bc21f1f9288d",
      group: "Operations"
   },
   "Consultancy": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg7-min.jpg?alt=media&token=d1aa3a22-3ad2-444c-995c-2ef21b110c51",
      group: "Supporting services "
   },
   "Insurance and Financing": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg8-min.jpg?alt=media&token=8a893604-b629-4df0-87f8-2741043b0ba0",
      group: "Supporting services "
   },
   "Repair and Maintenance": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg9-min.jpg?alt=media&token=7536cdba-b42f-42e5-b8ec-aa1fa5315ba1",
      group: "Supporting services "
   },
   "Training and Certification": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg10-min.jpg?alt=media&token=f34b185e-a0e4-459c-8f5a-44fb5da8d578",
      group: "Supporting services "
   },
   "Agriculture": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg11-min.jpg?alt=media&token=c8a282b1-b85a-453f-ad6f-01964ecec46e",
      group: "Industry / Field"
   },
   "Construction and Planning": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg12-min.jpg?alt=media&token=8d73a93c-9a4e-4997-92f1-1420a468f253",
      group: "Industry / Field"
   },
   "Defence Security and Surveillance": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg13-min.jpg?alt=media&token=84246745-9ed7-449b-8887-c179ead73fb4",
      group: "Industry / Field"
   },
   "Energy": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg14-min.jpg?alt=media&token=bb85495b-35f6-4a77-acc7-60fa204d6ede",
      group: "Industry / Field"
   },
   "Media Events and Creative": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg15-min.jpg?alt=media&token=58a6a422-4ea6-47e6-838d-ed9d4022a6cd",
      group: "Industry / Field"
   },
   "Medical and Healthcare": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg16-min.jpg?alt=media&token=b435cd90-b7c5-41eb-bdae-da57571484e4",
      group: "Industry / Field"
   },
   "Transport": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg17-min.jpg?alt=media&token=6a838a62-6dfa-4c24-bddd-3e56f77c7569",
      group: "Industry / Field"
   },
   "Conservation and Environment": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg18-min.jpg?alt=media&token=10ec1c05-25d8-47c9-a5b5-75e8b67f9ef6",
      group: "Giving Back"
   },
   "Education and Research": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg19-min.jpg?alt=media&token=64395a83-9a41-4be5-85c3-2bd76d28bbe6",
      group: "Giving Back"
   },
   "Emergency Response and SAR": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg20-min.jpg?alt=media&token=7ea6142a-18e5-4241-a29f-bc8ed7fb778b",
      group: "Giving Back"
   },
   "Humanitarian Aid and Disaster Relief": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg21-min.jpg?alt=media&token=7838aa88-2b83-4fa8-8006-5c23f7bcec28",
      group: "Giving Back"
   },
   "Public Safety": {
      url: "https://firebasestorage.googleapis.com/v0/b/airwards-10ac0.appspot.com/o/images%2Fimg22-min.jpg?alt=media&token=a8e838ea-9189-43b7-b7f2-ad73d3ed08f6",
      group: "Giving Back"
   }
}
