export const getCategories = (entrants) => {
   return [...new Set(entrants.map(item => item.company_info.category))];
}

export const containsNull = (obj) => {
   for(let key of Object.keys(obj)){
      if(!obj[key]){
         return true
      }
   }
   return false
}
