import View from "./theme/View";
import {ImageList, ImageListItem} from "@mui/material";
import Typography from "./theme/Typography";
import React from "react";


const YourVotes = ({votes}) => {
   return (
      <View flex alignItems="center" column>
         <Typography
            align={'center'}
            variant={'h5'}
            component={'h2'}
         >{"Your votes"}</Typography>
         <ImageList sx={{width: 300, height: 100 * Math.ceil(votes.length / 3)}} gap={0} cols={3} variant="quilted"
                    rowHeight={100}>
            {votes.map((item) => (
               <>
                  {item ?
                     <ImageListItem key={item.company_info.id}>
                        <img
                           src={`${item.company_info.cover_image}`}
                           alt={'company logo'}
                           loading="lazy"
                        />
                     </ImageListItem>
                     : null
                  }
               </>
            ))}
         </ImageList>
      </View>
   )
}

export default YourVotes
