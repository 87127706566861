import React from 'react'
import {useViewport} from "../../utils/dimensions/Viewport";
import {Fade} from "@mui/material";
import {makeStyles} from "@mui/styles";
import View from "../theme/View";
import {getImageOriginalProportion} from "../../utils/dimensions/dimensions";
import S from "../../res/styles";

const ImageCustom = ({
                         alt,
                         src,
                         paddingAlreadyUsed = 0,
                         desktopHeight = 550,
                         exactHeight,
                         exactWidth,
                         objectFit = 'contain',
                         noFade = false,
                         fullWidth,
                         position='center'
                     }) => {
    const {isXS} = useViewport();
    const screenDimensions = useViewport();
    let imgWidth;
    let imgHeight;

    const [isImgLoad, setIsImgLoad] = React.useState(false);
    const [proportion, setProportion] = React.useState(exactWidth && exactHeight ? exactWidth / exactHeight : 1);

    const onLoad = () => {
        if (!noFade) setIsImgLoad(true);
    }


    if (!exactWidth || !exactHeight) {
        getImageOriginalProportion(src).then((imageProportion) => {
            setProportion(imageProportion)
        });
    }

    if (isXS) {
        imgWidth = screenDimensions.width - paddingAlreadyUsed;
        imgHeight = imgWidth / proportion;
    } else {
        imgWidth = fullWidth ? '100%' : desktopHeight * proportion;
        imgHeight = desktopHeight;
    }

    //const classes = useStyles();

    return (
        /*<LazyLoad
            height={imgHeight}
            width={'100%'}
            offset={2000}
        >*/
        <View style={{
            width: '100%',
            height: imgHeight,
            position: 'relative',
            ...S.layout.centerCenter,
            ...S.layout.flex,
            justifyContent: position
        }}>
            <Fade in={noFade || isImgLoad}>
                <View style={{
                    maxWidth: '100%',
                    width: imgWidth,
                    height: imgHeight,
                    position: 'relative',
                    ...S.layout.centerCenter
                }}>
                    <img
                        src={src}
                        alt={alt}
                        onLoad={onLoad}
                        //className={classes.mainImage}
                        //height={imgHeight}
                        //width={imgWidth}
                        // quality={100}
                        // layout={'fill'}
                        // objectFit={objectFit}
                    />
                </View>
            </Fade>
        </View>
        /*</LazyLoad>*/
    )
}

const useStyles = makeStyles((theme) => ({
    mainImage: {
        //maxWidth: '100%',
        //objectFit: 'contain',
    }
}));

ImageCustom.propTypes = {}

export default ImageCustom;
