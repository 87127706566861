import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
   apiKey: "AIzaSyB5zaFeVxiqhRnMsiDynJlknQF8ZeszK-4",
   authDomain: "airwards-10ac0.firebaseapp.com",
   projectId: "airwards-10ac0",
   storageBucket: "airwards-10ac0.appspot.com",
   messagingSenderId: "867526693740",
   appId: "1:867526693740:web:521a9aafb01729ff9f1949"
};

const app = initializeApp(firebaseConfig);

export const firestoreDb = getFirestore(app);
