
const dimensions = {
    drawerWidth : 240,
    sidebarDimension : 5,

    //ListIcon
    listIconMinWidth: 35,
    listIconSize: '1.25rem',

    //Toolbar
    toolbarHeight: 50,

    bodyMaxWidthMedium: 800,
    bodyMaxWidthLarge: 1100,
}
export default dimensions
