import {doc, getDoc, writeBatch} from "firebase/firestore";
import {firestoreDb} from "../config/firebase";


export const checkIfEmailIsUsed = async (email) => {
   const docRef = doc(firestoreDb, "public_voting_users", email);
   const docSnap = await getDoc(docRef);
   return docSnap.exists()
}
export const submitPublicVotes = async (email, votes) => {
   const batch = writeBatch(firestoreDb);
   for (let category of Object.keys(votes)) {
      const docRef = doc(firestoreDb, "public_voting", votes[category]);
      batch.set(docRef, {
         votes: [
            email
         ],
         category: category
      }, {merge: true});
   }
   const userRef = doc(firestoreDb, "public_voting_users", email);
   batch.set(userRef, {votes: votes, vote_completed: true },{merge: true});

   return batch.commit();
}

export const isEmailUsed = async (email) => {
   const docRef = doc(firestoreDb, "public_voting_users", email);
   const docSnap = await getDoc(docRef);
   if (docSnap.exists() && docSnap.data()['vote_completed']) {
      return true
   }
   return false
}
