import React from 'react'
import View from "../../../components/theme/View";
import {MenuItem, MenuList} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "../../../components/theme/Button";
import Paper from "../../../components/theme/Paper";
import SocialMediaMenuIcons from "./SocialMediaMenuIcons";
import Typography from "../../../components/theme/Typography";
import {trackEvent} from "../../../utils/eventsTracking/gtag";
import {events} from "../../../utils/eventsTracking/events";
// import {useHistory} from "react-router-dom";
import Link from "../../../components/theme/Link";

const MenuHover = ({title, elements, href, oppositeColor}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showSub, setShowSub] = React.useState(false);
    // let history = useHistory();

    function handleHover(event) {
        if (anchorEl !== event.currentTarget && anchorEl === null) {
            setAnchorEl(event.currentTarget);
            setShowSub(true);
        } else {
            //if(anchorEl !== null) handleClose()
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setShowSub(false);
    };

    const handleClick = (event) => {
        handleHover(event);
        if (href) {
            trackEvent(events.button_clicked_menu, {navigate: title})
            //if (href) router.push(`${href}`)
        }
    };

    const handleClickSubElement = (event, element) => {
        handleClose(event);
        if (element?.href) {
            trackEvent(events.button_clicked_menu, {navigate: element.title})
            //router.push(`${element.href}`)
        }
    };

    return (
        <View row flex>
            <View
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                //onClick={handleClick}
                onMouseEnter={handleHover}
                onMouseLeave={handleClose}
                className={classes.element}
            >
                <Link href={href || null} onClick={handleClick} underline={'none'}>
                    <Button
                        oppositeColor={!oppositeColor}
                        noHover
                        variant={'text'}
                    >
                        {title}
                    </Button>
                    {/*<Typography oppositeColor weight={600} clickable>{title}</Typography>*/}
                    {elements &&
                    /*<Popper
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        role={undefined} transition disablePortal
                    >
                        {({TransitionProps, placement}) => (

                        )}
                    </Popper>*/



                    /*<Grow
                        in={showSub}
                        style={{
                            transformOrigin: 'center top',
                            position: 'absolute'
                        }}
                    >*/
                        <div className={classes.hover+" hover_show "}>
                        <Paper elevation={3}>
                            <MenuList
                                //open={Boolean(anchorEl)}
                                onMouseLeave={handleClose}
                                //MenuListProps={{ onMouseLeave: handleClose }}
                            >
                                {elements && elements.map(function (element) {
                                    return (
                                        <Link href={element.href || null}
                                              onClick={(event) => handleClickSubElement(event, element)}
                                              underline={'none'}
                                        >
                                            <MenuItem>
                                                {element?.icon && element?.icon2 &&
                                                <SocialMediaMenuIcons icon={element.icon}
                                                                      icon2={element.icon2}/>
                                                }
                                                <Typography clickable>{element.title}</Typography>
                                            </MenuItem>
                                        </Link>
                                    );
                                })}
                            </MenuList>
                        </Paper>
                        </div>
                    /*</Grow>*/

                    }
                </Link>
            </View>
        </View>
    )
}

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    element: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        position:'relative',
        '&:hover .hover_show': {
            left: "0",
            opacity: "1",
            WebkitTransform: "translate3d(0,0,0)",
            MozTransform: "translate3d(0,0,0)",
            MsTransform: "translate3d(0,0,0)",
            OTransform: "translate3d(0,0,0)",
            transform: "translate3d(0,0,0)",
            WebkitTransition: "left 0s linear,transform .2s ease-out,opacity .2s ease-out",
            MozTransition: "left 0s linear,transform .2s ease-out,opacity .2s ease-out",
            MsTransition: "left 0s linear,transform .2s ease-out,opacity .2s ease-out",
            OTransition: "left 0s linear,transform .2s ease-out,opacity .2s ease-out",
            transition: "left 0s linear,transform .2s ease-out,opacity .2s ease-out"
        }
    },
    grow: {},
    hover: {
        WebkitBoxSizing: "border-box",
        MozBoxSizing: "border-box",
        boxSizing: "border-box",
        position: "absolute",
        top: "100%",
        zIndex: "1002",
        marginLeft: "-1em",
        left: '-99999px',
        padding: "1em",
        opacity: "0",
        whiteSpace: "nowrap",
        textAlign: "left",
        WebkitTransform: "translate3d(0,10px,0)",
        MozTransform: "translate3d(0,10px,0)",
        MsTransform: "translate3d(0,10px,0)",
        OTransform: "translate3d(0,10px,0)",
        transform: "translate3d(0,10px,0)",
        WebkitTransition: "left 0s .2s linear,transform 0s .2s linear,opacity .2s ease-out",
        MozTransition: "left 0s .2s linear,transform 0s .2s linear,opacity .2s ease-out",
        MsTransition: "left 0s .2s linear,transform 0s .2s linear,opacity .2s ease-out",
        OTransition: "left 0s .2s linear,transform 0s .2s linear,opacity .2s ease-out",
        transition: "left 0s .2s linear,transform 0s .2s linear,opacity .2s ease-out",
    },
}));

export default MenuHover
