import React from "react";
import PropTypes from "prop-types";
import {darken, Drawer, Hidden, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import R from "../../../res/variables";
import View from "../../../components/theme/View";
import MenuSidebarElements from "../Menu/MenuSidebarElements";

const drawerWidth = R.dimensions.drawerWidth;

const Sidebar = ({window, mobileOpen, handleDrawerToggle, mount}) => {

    const container = window !== undefined ? () => window().document.body : undefined;
    const classes = useStyles();
    const theme = useTheme();

    const drawer = (
        <View className={classes.drawerBody}>
            <MenuSidebarElements handleDrawerToggle={handleDrawerToggle}/>
        </View>
    );

    return (
        <View>
            {/* The implementation should be with js (default) to avoid seo duplication of links. */}
            {mount &&
            <Hidden smUp>
                <nav className={classes.drawer} aria-label="drawer-mobile-menu">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        SlideProps={{
                            mountOnEnter: false,
                            unmountOnExit: false
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Hidden>
            }
        </View>
    )
};


const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    // necessary for content to be below app bar
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: R.palette.themeDark ? R.palette.backgroundSidebarDark : R.palette.backgroundPaperLight
    },
    drawerBody: {
        //Remove the space for the scrollbar
        //width: drawerWidth - R.dimensions.sidebarDimension - 1,
    },
    addLinkButton: {
        background: `linear-gradient(${darken(theme.palette.primary.main, 0.6)}, ${darken(theme.palette.primary.main, 0.7)});`,
        '&:hover': {
            background: `linear-gradient(${darken(theme.palette.primary.main, 0.5)}, ${darken(theme.palette.primary.main, 0.7)});`,
        },
    }
}));


Sidebar.propTypes = {
    window: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    mobileOpen: PropTypes.bool,
};

export default Sidebar;
