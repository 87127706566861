import Typography from "../components/theme/Typography";
import React from "react";

export const entriesMap = {
   id: "ID",
   first_name: "First Name",
   last_name: "Last Name",
   email: "Email",
   job_title: "Job Title",
   company_name: "Comapany Name",
   phone_number: "Phone",
   website_url: "Website",
   country: "Country",
   city: "City",
   social_media_urls: "Social Media",
   company_type: "Company Type",
   turnover: "Turnover",
   num_employees: "Number of Employees",
   company_age: "",
   entry_title: "Entry Title",
   entry_description: "Entry Description",
   cover_image: "",
   additional_image_1: "",
   additional_image_2: "",
   use_case_country: "Use Case Country",
   is_under_embargo: "Under Embargo",
   embargo_end_date: "",
   launch_date: "Launch Date",
   reason: "Nomination Reasons",
   statement: "Supporting Statement",
   innovation_description:
      <Typography variant={'h6'} style={{fontWeight: 400}}>
         <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>INNOVATION</Typography> - What
         is <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}
                        lowercase>different</Typography> and <Typography variant={'h6'} style={{
         fontWeight: 600,
         display: 'inline'
      }}>innovative</Typography> about your user case from others in the industry?</Typography>,
   responsible_description: <Typography variant={'h6'} style={{fontWeight: 400}}>
      <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>RESPONSIBILITY</Typography> - What
      is <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}
                     lowercase>responsible</Typography> about your user case?</Typography>,
   impact_description:
      <Typography variant={'h6'} style={{fontWeight: 400}}>
         <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>IMPACT</Typography> - What <Typography
         variant={'h6'} style={{fontWeight: 600, display: 'inline'}} lowercase>real-world impact</Typography> is your
         use case having (or likely to have)?</Typography>,

   innovation_description_round_2: <Typography variant={'h6'} style={{fontWeight: 400}}>
      <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>INNOVATION</Typography> - How is your use
      case either <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>adaptive</Typography>, <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>imitative</Typography> or <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>novel</Typography> in your field and what is unique and different from others in your field
      / category about your use case?
   </Typography>,
   responsible_description_round_2: <Typography variant={'h6'} style={{fontWeight: 400}}><Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>RESPONSIBILITY</Typography> - How is your use case responding to <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>environmental</Typography> and <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>sustainable</Typography> practices, promoting/providing <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>safety</Typography>, and is <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>socially responsible</Typography>?</Typography>,
   impact_description_round_2: <Typography variant={'h6'} style={{fontWeight: 400,}}><Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>IMPACT</Typography> - How is your use case having a <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>positive effect</Typography>, impacting <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>beneficiaries</Typography> (both indirect and direct), and what are the resulting <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>tangible outcomes</Typography>?</Typography>,
   category: "",
   scalability: <Typography variant={'h6'} style={{fontWeight: 400}}>Is your use case able to be <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>replicated / scalable</Typography> and if so, what are your plans and what do you need?</Typography>,
   ecosystem_improvs: <Typography variant={'h6'} style={{fontWeight: 400}}>What is your use case doing to advance the whole drone <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>ecosystem</Typography> and overall drone <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>knowledge base</Typography>?</Typography>,
   obstacles: <Typography variant={'h6'} style={{fontWeight: 400}}>What are the biggest <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>factors</Typography> and <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>barriers</Typography> to the <Typography variant={'h6'} style={{fontWeight: 600, display: 'inline'}}>success</Typography> of your use case?</Typography>,
   video_url: "Video"

}
