import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import S from "../../res/styles";
import {Grid as MuiGrid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useViewport} from "../../utils/dimensions/Viewport";


const useStyles = makeStyles(theme => ({
    root: {
        ...S.layout.overflowHidden,
    },
    overflowVisible: {
        ...S.layout.overflowVisible,
    },
    fiveElements: {
        flexGrow: 0,
        maxWidth: '20%',
        flexBasis: '20%',
    },
    eightElements: {
        flexGrow: 0,
        maxWidth: '12.5%',
        flexBasis: '12.5%',
    },
    hidden:{
        display:'none'
    }
}));

const useCustomElements = (customSize, xs, sm, md, lg, xl) => {
    // check if it's used 2.4 (5 elements) and insert manually into the design if is the right screen size and there are not other sizes in priority
    const activePropList = [!!xs, !!sm, !!md, !!lg, !!xl];
    const isCustomElements = [xs === customSize, sm === customSize, md === customSize, lg === customSize, xl === customSize];
    let isActiveScreen = [];
    const {width, height, isXS, isSM, isMD, isLG, isXL} = useViewport();
    isActiveScreen[4] = isXL;
    isActiveScreen[3] = isLG;
    isActiveScreen[2] = isMD;
    isActiveScreen[1] = isSM;
    isActiveScreen[0] = isXS;

    for (let i = activePropList.length - 1; i >= 0; i--) {
        if(isActiveScreen[i] && activePropList[i]){
            //this return true if there is the custom element and false if not
            return isCustomElements[i];
        }
    }
    return false;
}

const Grid = ({
                  className,
                  children,
                  overflowVisible,
                  spacing,
                  wrap,
                  item,
                  direction,
                  container,
                  alignItems,
                  alignContent,
                  justify,
                  xs,
                  sm,
                  md,
                  lg,
                  xl,
                  show=true,
                  ...rest
              }) => {
    const classes = useStyles();

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.overflowVisible]: overflowVisible,
            [classes.hidden]: !show,
            [classes.fiveElements]: useCustomElements(2.4, xs, sm, md, lg, xl),
            [classes.eightElements]: useCustomElements(1.5, xs, sm, md, lg, xl),
        },
        className
    );

    return (
        <MuiGrid
            spacing={spacing}
            wrap={wrap}
            item={item}
            direction={direction}
            container={container}
            alignItems={alignItems}
            alignContent={alignContent}
            justify={justify}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg !== 2.4 && lg !== 1.5 ? lg : null}
            xl={xl}
            {...rest}
            className={rootClassName}
        >
            {children}
        </MuiGrid>
    );
};

Grid.propTypes = {
    children: PropTypes.node,
    overflowVisible: PropTypes.bool,
    spacing: PropTypes.number,
    item: PropTypes.bool,
    container: PropTypes.bool,
    direction: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
    alignContent: PropTypes.oneOf([false, 'stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    justify: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
    wrap: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
    xs: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    sm: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    md: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    xl: PropTypes.oneOf([false, 'auto', true, 1, 1.5, 2, 2.4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

Grid.defaultProps = {
    overflowVisible: false,
};

export default Grid;
