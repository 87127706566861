import React from 'react';
import classNames from 'classnames';
import {CircularProgress as MuiCircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {},
}));

const CircularProgress = ({
                              className,
                              size = 20,
                              ...rest
                          }) => {

    const classes = useStyles();

    const rootClassName = classNames(
        {
            [classes.root]: true,
        },
        className
    );

    return (
        <MuiCircularProgress
            size={size}
            {...rest}
            className={rootClassName}
        />
    );
};

CircularProgress.propTypes = {};

export default CircularProgress;
