import React from 'react'
import {makeStyles} from "@mui/styles";
import QuestionBox from "./QuestionBox";
import Typography from "../theme/Typography";
import Spacer from "../Spacer/Spacer";
import View from "../theme/View";

const QuestionsList = ({id, questsList, questsDescription, onVoteChange, round, initialVotes = []}, error) => {
   const classes = useStyles();

   return (
      <View>
         <Spacer size={2}/>
         <Typography capitalize variant={'h6'}
                     style={{fontWeight: 700}}>{"Your Votes"}</Typography>
         <Spacer/>
         {questsList && questsList.map((item, index) => (
            <>
               <QuestionBox
                  item={item}
                  index={index}
                  initialVote={initialVotes[index]}
                  onVoteChanged={(value) => onVoteChange(id, index, value)}
                  round={round}
                  error={error}
               />
            </>
         ))}
      </View>
   )
}

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',

   },
}));

export default QuestionsList
