import _ from "lodash"
export const isString = (data) => {
    return typeof data === 'string' || data instanceof String
}

export const convertToCSV = (objArray, avoidDollars) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    let line = '';
    for (let key of _.keys(array[0])) {
        if(avoidDollars){
            if (line !== '') line += ','
        } else {
            if (line !== '') line += '$'
        }
        line += key
    }
    str += line + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if(avoidDollars){
                if (line !== '') line += ','
            } else {
                if (line !== '') line += '$'
            }

            if(typeof array[i][index] == 'string'){
                line += array[i][index].replace(/(?:\r\n|\r|\n)/g, ' ');
            } else {
                line += array[i][index]
            }
        }

        str += line + '\r\n';
    }

    return str;
}
