import { doc, getDoc } from "firebase/firestore";
import {firestoreDb} from "../config/firebase";

export const checkSignin = async (email, password) => {
   if(email && password) {
      const docRef = doc(firestoreDb, "judges", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
         const data = docSnap.data()
         if(data.password === password) {
            return email
         }
      }
   }
   return null
}
