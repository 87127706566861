import React from 'react';
import classNames from 'classnames';
import {Paper as MuiPaper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from 'prop-types';
import S from "../../res/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    spacing: props => ({
        padding: theme.spacing(props.spacing),
    }),
    transparent: {
        backgroundColor: 'transparent'
    },
    centerCenter: {...S.layout.centerCenter},
}));

const Paper = ({
                   className,
                   spacing = 1,
                   elevation = 0,
                   transparent = false,
                   centerCenter,
                   ...rest
               }) => {

    const classes = useStyles({spacing});

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.spacing]: spacing,
            [classes.transparent]: transparent,
            [classes.centerCenter]: centerCenter,
        },
        className
    );

    return (
        <MuiPaper
            elevation={elevation}
            {...rest}
            className={rootClassName}
        />
    );
};

Paper.propTypes = {
    centerCenter: PropTypes.bool
};

export default Paper;
