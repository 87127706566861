import React from 'react'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {FormControlLabel, Radio} from "@mui/material";
import Typography from "../theme/Typography";
import R from "../../res/variables";

const CustomRadioGroup = ({min = 1, max = 5, label, initialVote, onVoteChanged, error, hideLabel = false}) => {
    const [value, setValue] = React.useState(initialVote || 0); // TODO min or null ?
    const isXS = window.innerWidth < 600;

    const handleChange = (event) => {
        setValue(event.target.value);
        onVoteChanged(event.target.value)
    };

    function renderItem(index) {
        return <FormControlLabel value={index} control={<Radio style={{paddingLeft: isXS?0:9, paddingRight: isXS?0:9}}/>} label={hideLabel?'':index} labelPlacement={'top'}/>
    }

    return (
        <FormControl component="fieldset" error={error}>
            {label && <FormLabel component="legend"><Typography variant={'body1'}>{label}</Typography></FormLabel>}
            <RadioGroup row
                        name="row-radio-buttons-group"
                        value={value || 0}
                        onChange={handleChange}
            >
               <Typography variant={'body1'}>{Array.from({length: max - min + 1}, (_, i) => renderItem(i + min))}</Typography>
            </RadioGroup>
        </FormControl>
    );
}

export default CustomRadioGroup
