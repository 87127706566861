import React from 'react'
import View from "../theme/View";
import {makeStyles} from "@mui/styles";
import Typography from "../theme/Typography";
import Spacer from "../Spacer/Spacer";
import {useViewport} from "../../utils/dimensions/Viewport";
import VoteBox from "../VoteBox/VoteBox";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash"
import Grid from "../theme/Grid";

const CompaniesList = ({
                          companiesList,
                          questsList,
                          questsDescription,
                          onVoteChange,
                          onFeedbackChange,
                          initialVotes,
                          initialFeedback,
                          category,
                          group,
                          onBackButtonClicked,
                          round,
                          submitError,
                          isPublic = false
                       }) => {
   const classes = useStyles();
   const {isXS} = useViewport();
   return (
      <View className={classes.root} centerCenter flex column>
         {onBackButtonClicked &&
         <View flex justify={'flex-start'} fullWidth>
            <IconButton onClick={onBackButtonClicked}>
               <ArrowBackIcon/>
            </IconButton>
         </View>
         }
         <View className={classes.wrapper}>
            <Typography
               align={'center'}
               variant={isXS ? 'h3' : 'h4'}
               component={'h2'}
            >{isPublic ? group + ' - ' + category : category}</Typography>
            <Spacer size={2}/>
            <Grid container lg={isPublic ? 12 : 12} flex justifyContent={'center'}>
               {companiesList && companiesList.map((item, index) => (
                  <Grid item xs={isPublic ? 6 : 12} lg={isPublic ? 2.3 : 12} md={isPublic ? 2.3 : 12} key={item.company_info.id}  >
                     <VoteBox
                        item={item}
                        index={index}
                        questsList={questsList}
                        questsDescription={questsDescription}
                        initialVotes={initialVotes?.[item.company_info.id]}
                        initialFeedback={initialFeedback?.[item.company_info.id]}
                        onVoteChange={onVoteChange}
                        onFeedbackChange={(value, index) => {onFeedbackChange(item.company_info.id, index, value)}}
                        round={round}
                        submitError={submitError}
                        isPublic={isPublic}
                        submitted={
                           initialVotes?.[item.company_info.id]
                           && _.every(initialVotes?.[item.company_info.id] || [], (item) => (item !== 0))
                           && _.every(initialFeedback?.[item.company_info.id] || [], (item) => (item !== ''))}
                     />
                  </Grid>
               ))}
            </Grid>
         </View>
         {
            !_.isEmpty(submitError) &&
            <Typography
               align={'center'}
               variant={'h8'}
               component={'h8'}
               style={{color: 'red'}}
            >Please complete the entrants you already started judging</Typography>
         }
      </View>
   )
}

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
   },
   wrapper: {
      width: '90%',

   },
}));

export default CompaniesList
